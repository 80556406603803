<template>
  <div class="c_nav">
    <div class="nav_box">
      <div class="box_one">
        <div class="box_logo" @click="to('/')">
          <!-- <img src="../assets/images/logo.png" alt="" /> -->
          游戏选号网
        </div>
        <div class="box_search">
          <div class="search_content">
            <div class="content_select">
              <div class="select_item">
                <div class="item_box" @click="selectTabTap(0)">
                  <span>{{ selectTabList.game.name || '选择游戏' }}</span><img src="@/assets/images/down_bottom.png" alt="" />
                </div>
              </div>
            </div>
            <div class="select_list" v-if="selectTab">
              <div class="list_icon" :class="selectStyle"></div>
              <div class="list_title">选择游戏</div>
              <div class="list_content">
                <div class="content_item" v-for="item in searchGame" :key="item.id" @click="tapSelectItem(item.id, item.name)">
                  <div class="item_box" :class="item.id == selectTabList.game.id ? 'active' : ''">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <img class="content_line" src="@/assets/images/line_01.png" alt="" />
            <div class="content_search">
              <el-input placeholder="找不到想要的游戏？搜一搜吧~" v-model="selectTabList.game.keyword" clearable>
              </el-input>
            </div>
            <div class="content_btn" @click="tapSearch"><img src="@/assets/images/search.png" alt="" /></div>
          </div>
          <div class="search_hot">
            <div class="hot_title">
              <img src="@/assets/images/hot.png" alt="" />
              <span>热搜：</span>
            </div>
            <div class="hot_list">
              <div class="list_item" v-for="(item, index) in hotGameList" :key="index" @click="to(`/list?gameId=${item.id}`)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_two">
        <div class="two_hot" @click="to('/game')">
          <img class="hot_icon1" src="@/assets/images/hot_w.png" alt="" />
          <span>热门游戏</span>
          <img class="hot_icon2" src="@/assets/images/right.png" alt="" />
        </div>
        <div class="two_nav">
          <div class="nav_item" @click="to('/')">
            <span>首页</span>
            <div class="item_icon" :class="{
                active: activeIndex == 1
              }"></div>
          </div>
          <div class="nav_item" @click="to('/game')">
            <span>我要买号</span>
            <div class="item_icon" :class="{
                active: activeIndex == 2
              }"></div>
          </div>
          <div class="nav_item" @click="al()">
            <span>我要卖号</span>
            <div class="item_icon" :class="{
                active: activeIndex == 3
              }"></div>
          </div>
   
          
        </div>
        <div class="nav_notice">
          <slot name="notice"></slot>
        </div>
      </div>
    </div>

    </div>

  </div>
</template>
 
<script>
import * as api from '@/api'
import clip from '@/utils/clipboard'
export default {
  name: 'cNav',
  components: {},
  data() {
    return {
      searchGame: [],
      hotGameList: [],
      search: '',
      selectTab: false,
      selectTabList: {
        game: {
          name: '',
          id: 0,
          keyword: '',
        },
      },
      selectStyle: 'select00',
      activeIndex: 0,
      path: '',
      gameId: 0,
    }
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  created() {
    this.activeIndex = this.index
    this.getGameList()

    //查询当前路径
    this.path = this.$route.path

    this.gameId = this.$route.query.gameId
    if (this.$route.query.hasOwnProperty('keyword')) {
      this.selectTabList.game.keyword = this.$route.query.keyword
    }
  },
  methods: {
    zh() {
      window.open('https://aizuhaowan.vip.zuhaowan.net/')
    },
    to(path = '') {
      //获取当前路由路径

      if (this.$route.fullPath == path) {
        return false
      }

      this.$router.push(path)
    },
    al(){
      var s = "近期有不法分子冒充平台客服身份骗取钱财,账号等,一定在交易前核实客服身份,现将客服公示,交易前一定来核对\n" +
          "\n" +
          "回收客服QQ：1146035878\n" +
          "回收客服VX：jiezou2222\n" +
          "回收评估8群：930992257\n" +
          "\n" +
          "投诉/合作/建议：\n" +
          "\n" +
          "此名单只公示了部分优秀客服, 没有看到的可以联系以上客服咨询客服的真实性，避免上当受骗！"
      alert(s)
      // clip(s, event)
    },
    hotGame(data) {
      let res = data.filter((item, index) => {
        return index < 5
      })
      return res
    },
    async getGameList() {
      let res = await api.gameList()
      if (res.code == 200) {
        this.searchGame = res.data

        if (this.path == '/list' && this.gameId != 0) {
          this.searchGame.forEach((item) => {
            if (item.id == this.gameId) {
              this.selectTabList.game.name = item.name
              this.selectTabList.game.id = item.id
            }
          })
        } else {
        }

        this.hotGameList = this.hotGame(res.data)
      }
    },
    async getUser() {
      let res = await api.getUser()

      if (res.code == 200) {
        this.userData = res.data
      }
    },
    gameList() {
      this.$router.push({
        path: '/game',
        query: {
        },
      })
    },
    tapSearch() {
      if (this.selectTabList.game.id == 0) {
        this.$message.error('请先选择游戏!')
        return false
      }
      this.$router.push(
        `/list?gameId=${this.selectTabList.game.id}&keyword=${this.selectTabList.game.keyword}`
      )
    },
    tapSelectItem(id, name) {
      this.selectTabList.game.id = id
      this.selectTabList.game.name = name
      this.selectTab = false
    },
    selectTabTap(v) {
      this.selectTab = !this.selectTab
    },
  },
  watch: {
    index(v) {
      this.activeIndex = v
    },
    $route(to, from) {
      this.path = to.path
      this.gameId = to.query.gameId

      this.getGameList()
    },
  },
}
</script>

<style lang="less" scoped>
@keyframes select00 {
  0% {
    left: 0;
  }
  to {
    left: 80px;
  }
}
@keyframes select01 {
  0% {
    left: 50px;
  }
  to {
    left: 180px;
  }
}
@keyframes select02 {
  0% {
    left: 50px;
  }
  to {
    left: 310px;
  }
}

@keyframes select10 {
  0% {
    left: 180px;
  }
  to {
    left: 50px;
  }
}
@keyframes select12 {
  0% {
    left: 180px;
  }
  to {
    left: 310px;
  }
}
@keyframes select21 {
  0% {
    left: 310px;
  }
  to {
    left: 180px;
  }
}
@keyframes select20 {
  0% {
    left: 310px;
  }
  to {
    left: 50px;
  }
}

@keyframes navWidth {
  0% {
    background: #fff;
    width: 0;
  }
  to {
    width: 35px;
    background: #f54523;
  }
}
.c_nav {
  background: #fff;
  .nav_box {
    width: 1400px;
    margin: 0 auto;
    .box_one {
      height: 140px;
      display: flex;
      width: 100%;
      .box_logo {
        margin-top: 30px;
        height: 70px;
        cursor: pointer;
        font-size: 30px;
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #f54523;
        img {
          height: 100%;
        }
      }
      .box_search {
        margin-top: 30px;
        margin-left: auto;
        .search_content {
          width: 600px;
          height: 50px;
          border: 1px solid #f54523;
          border-radius: 5px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          position: relative;
          .content_select {
            height: 100%;
            display: flex;
            flex: 1;
            box-sizing: border-box;
            // padding-right: 20px;
            .select_item {
              flex: 1;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              .item_box {
                cursor: pointer;
                span {
                  font-size: 16px;
                  color: #373737;
                }
                img {
                  margin-left: 4px;
                  width: 9px;
                }
              }
            }
          }
          .select_list {
            box-sizing: border-box;
            position: absolute;
            top: 75px;
            left: 0;
            width: 600px;
            border: 1px solid #f54523;
            border-radius: 5px;
            padding: 20px;
            background-color: #fff;
            z-index: 9999;
            .list_icon {
              position: absolute;
              top: -7px;
              width: 8px;
              height: 8px;
              border: 2px solid #f54523;
              border-right: 0;
              border-bottom: 0;
              background-color: #fff;
              transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              z-index: 999;
              -webkit-animation-duration: 0.5s;
              -webkit-animation-iteration-count: 1;
              -webkit-animation-delay: 0s;
            }
            .list_title {
              font-size: 16px;
              font-weight: bold;
              padding-bottom: 10px;
              border-bottom: 1px solid #eee;
            }
            .list_content {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              .content_item {
                width: calc(100% / 4);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                cursor: pointer;
                .item_box {
                  width: 100px;
                  height: 30px;
                  font-size: 12px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #2c2c2c;
                  &:hover {
                    border: 1px solid #f54523;
                    color: #f54523;
                  }
                  &.active {
                    border: 1px solid #f54523;
                    color: #f54523;
                  }
                }
              }
            }
            .select00 {
              -webkit-animation-name: select00;
              left: 80px;
            }
            .select01 {
              -webkit-animation-name: select01;
              left: 180px;
            }
            .select02 {
              -webkit-animation-name: select02;
              left: 310px;
            }
            .select10 {
              -webkit-animation-name: select10;
              left: 50px;
            }
            .select12 {
              -webkit-animation-name: select12;
              left: 310px;
            }
            .select21 {
              -webkit-animation-name: select21;
              left: 180px;
            }
            .select20 {
              -webkit-animation-name: select20;
              left: 50px;
            }
          }
          .content_line {
            margin-left: auto;
            width: 2px;
            height: 35px;
          }
          .content_search {
            ::v-deep .el-input__inner {
              border: none;
              width: 350px;
            }
          }
          .content_btn {
            width: 66px;
            height: 100%;
            background: #f54523;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 18px;
            }
          }
        }
        .search_hot {
          padding: 20px 12px;
          display: flex;
          align-items: center;
          .hot_title {
            display: flex;
            align-items: center;
            img {
              width: 14px;
            }
            span {
              font-size: 16px;
              color: #7d7d7d;
              margin-left: 8px;
            }
          }
          .hot_list {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #7d7d7d;
            .list_item {
              cursor: pointer;
              margin: 0 22px;
              &:nth-child(1) {
                margin-left: 10px;
              }
              &:hover {
                color: #f54523;
              }
            }
          }
        }
      }
    }

    .box_two {
      display: flex;
      .two_hot {
        box-sizing: border-box;
        width: 214px;
        height: 64px;
        display: flex;
        align-items: center;
        background: #f54523;
        border-radius: 5px 5px 0px 0px;
        padding: 0 24px;
        cursor: pointer;
        .hot_icon1 {
          width: 25px;
        }
        span {
          margin-left: 19px;
          font-size: 24px;
          color: #f5f5f5;
          font-weight: bold;
        }
        .hot_icon2 {
          margin-left: auto;
          width: 8px;
        }
      }

      .two_nav {
        padding-left: 20px;
        height: 64px;
        display: flex;
        align-items: center;
        .nav_item {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 46px;
          position: relative;

          cursor: pointer;
          span {
            font-size: 22px;
            color: #1a2129;
          }
          &:hover {
            span {
              color: #f54523;
            }
          }
          .item_icon {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            height: 6px;
            -webkit-animation-duration: 0.5s;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-delay: 0s;
          }
          .active {
            -webkit-animation-name: navWidth;
            width: 35px;
            background: #f54523;
          }
        }
      }
      .nav_notice {
        margin-left: auto;
      }
    }
  }
}

/deep/.liu_loading .el-dialog {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;

  .el-dialog__close {
    display: none;
  }
}

.sell_box_liu {
  width: 835px;
  // height: 580px;
  background: #ffffff;
  border-radius: 10px;

  .sell_box_liu_title {
    width: 835px;
    height: 78px;
    background: rgba(204, 201, 215, 0.12);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1d1b22;
  }
}

.sell_box_liu_content {
  padding-top: 40px;
  padding: 0 58px;
  box-sizing: border-box;

  .sell_box_liu_item {
    .sell_box_liu_item_title {
      position: relative;
      margin: 30px 0;

      .span1 {
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-55%);
        width: 6px;
        height: 25px;
        background: #f54523;
        border-radius: 3px;
      }

      span {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #171717;
      }
    }
  }

  .sell_box_liu_item_box {
    div {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4d4d4d;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
}

.sell_box_liu_button {
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 204px;
    height: 56px;
    background: linear-gradient(0deg, #ff765c, #f54523);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    margin-right: 30px;
  }

  div:nth-child(2) {
    background: linear-gradient(0deg, #8c6af1, #673ee2);
  }
}
</style>