import { post, get, updateAdd } from "./config";


//查询游戏列表
export function gameList(options) {
  return get("/game_list", options);
}


//查询游戏属性
export function property(options) {
  return get("/property", options);
}

//查询游戏角色
export function role(options) {
  return get("/role", options);
}

//查询游戏列表
export function swiper_list(options) {
  return get("/swiper_list", options);
}

//查询游戏列表
export function tips_list(options) {
  return get("/tips_list", options);
}


//查询游戏商品列表
export function list_details(options) {
  return get("/list_details", options);
}


//查询游戏需详情
export function detail(options) {
  return get("/detail", options);
}

//查询游戏系统
export function system(options) {
  return get("/system", options);
}

